import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { classNames } from 'primereact/utils';

import ImageComponent from 'shared/ImageComponent';

import { openLinkInBlank, handleRedirection } from 'utils/utils';
import { paginationDropdownOptions } from 'utils/utils';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import { UserDetailsContext } from 'context/userDetailsContext';
import { useSecureMessageContext } from '../../Context/SecureMessageContext';
import { handlePageNumberInPagination } from 'common/pagination';
import { useNavbarContext } from 'context/NavbarContext';
import { getSignedUrl } from 'modules/file_manager/services';
import { SentimentDot } from 'components/SentimentDot/SentimentDot';
import constants from 'constants/index';
import { truncateText } from 'utils/utility_functions/textTruncate';

function MessageListTable({ caseMessages }) {
  const [isForward, setIsForward] = useState(true);

  const history = useHistory();
  const userContext = useContext(UserDetailsContext);
  const { downloadFile } = useNavbarContext();

  let { id } = useParams();

  const {
    isLeadSpecific,
    isGlobalMessagesScreen,
    isFromCaseSpecificModule,
    next_Token,
    transition_Token,
    fetchCaseMessage,
    filterCaseId,
    filterMsgStatus,
    filterAuthorId,
    isFromClientPortal,
    handleMessageStatus,
    filterSentiment,
    filterParticipant,
  } = useSecureMessageContext();

  const [pageLimit, setPageLimit] = useState(getPageLimit() ?? 10);

  function getPageLimit() {
    if (isLeadSpecific && userContext) {
      return userContext?.firmDetails?.pagination_preference?.leads?.message;
    } else if (isFromCaseSpecificModule && userContext) {
      return userContext?.firmDetails?.pagination_preference?.case?.message;
    } else if (isGlobalMessagesScreen && userContext) {
      return userContext?.firmDetails?.pagination_preference?.messages?.self;
    } else if (isFromClientPortal && userContext) {
      return userContext?.firmDetails?.pagination_preference?.client_portals?.messages?.self;
    }
  }

  function getFilePathForPagination() {
    if (isLeadSpecific && userContext) {
      return { module: 'leads', subModule: '', listName: 'message' };
    } else if (isFromCaseSpecificModule && userContext) {
      return { module: 'case', subModule: '', listName: 'message' };
    } else if (isGlobalMessagesScreen && userContext) {
      return { module: 'messages', subModule: '', listName: 'self' };
    } else if (isFromClientPortal && userContext) {
      return { module: 'client_portals', subModule: 'messages', listName: 'self' };
    }
  }

  const handleNavigateToMessageThread = (e, rowData) => {
    e.preventDefault();
    let mainModule = isFromClientPortal
      ? '/client/messages'
      : isGlobalMessagesScreen
      ? '/messages'
      : `/${isLeadSpecific ? 'leads' : 'cases'}/${rowData?.case_id}/messages`;
    let url = mainModule;
    let stateToPass = { msg: rowData };

    if (rowData?.message_thread_id) url = `${mainModule}/${rowData?.message_thread_id}`;
    if (!rowData?.is_read) handleMessageStatus(e, rowData, rowData.is_read, id);

    handleRedirection(history, url, stateToPass);
  };

  const authorBodyTemplate = (rowData) => {
    // const { author_details } = rowData || {};
    // function navigateAuthor(e) {
    //   e.preventDefault();

    //   if (author_details && !isFromClientPortal) {
    //     let isClient = author_details?.access_level?.toLowerCase() === 'client';

    //     let url = '/';

    //     if (isClient) {
    //       url = author_details?.contact_id ? `/contacts/${author_details?.contact_id}` : '/contacts';
    //     } else {
    //       url = author_details?.firm_user_id ? `/settings/team-management/${author_details.firm_user_id}` : '/settings/team-management';
    //       // url = `/settings/team-management/${author_details.client_cognito_username}`
    //     }

    //     handleRedirection(history, url);
    //   }
    // }

    return (
      <>
        <span className="p-column-title text-break">Author</span>
        <div className="d-inline-table text-break" style={{ cursor: 'text' }}>
          {/* <div className="d-flex flex-nowrap align-items-center " onClick={(e) => navigateAuthor(e)}> */}
          <div className="d-flex flex-nowrap align-items-center ">
            <ImageComponent
              filePath={rowData?.created_by_user_image}
              fileName={rowData?.created_by_name?.first_name}
              fileSize="medium.jpg"
              avatarSize="medium"
              className={isFromClientPortal ? 'pe-none' : ''}
              style={{ cursor: 'text' }}
            />
            <div
              // className={rowData.is_read ? 'mt-1 text-break text-bold text-capitalize call-back' : 'mt-1 text-break text-bold call-back'}
              // className={`ms-1 ${classNames({ 'redirection-link': rowData.author_name && !isFromClientPortal })}`}
              className="ms-1 message-author-ellipsis-text"
            >
              {rowData.author_details?.firm_user_id && rowData?.author_details?.access_level === 'firm-admin' && (
                <i className="fas fa-user-tie me-2"></i>
              )}
              <span title={rowData?.author_name || undefined}>{rowData?.author_name || '-'}</span>
            </div>
          </div>
        </div>
      </>
    );
  };

  const caseNameBodyTemplate = (rowData) => {
    function navigate(e) {
      let url = '';
      let stateToPass = '';

      const mainModule = rowData?.is_lead ? '/leads' : '/cases';

      url = rowData?.case_id ? `${mainModule}/${rowData?.case_id}` : mainModule;

      stateToPass = rowData;
      e.preventDefault();
      if (rowData?.case_name && !isFromClientPortal) {
        if (!openLinkInBlank(url, stateToPass)) {
          history.push({
            pathname: url,
            state: stateToPass,
          });
        }
      }
    }

    return (
      <>
        <span className="p-column-title text-break">Case Name</span>
        <a
          href="/"
          onClick={navigate}
          // className="text-primary-dark text-decoration-none case-details-link"
          className={`text-decoration-none ${classNames({
            'redirection-link-no-text-transform': rowData.case_name && !isFromClientPortal,
            'pe-none': isFromClientPortal,
          })}`}
          title={rowData?.case_name ? rowData?.case_name : undefined}
        >
          {rowData?.case_name ? rowData?.case_name : '-'}
        </a>
      </>
    );
  };

  const stateBodyTemplate = (rowData) => {
    const { is_read } = rowData || {};

    return (
      <>
        <span className="p-column-title text-break">State</span>
        <div className="status-mark d-inline-table">
          {/* <InputSwitch
            checked={is_read}
            onChange={(e) => {
              handleMessageStatus(e, rowData, is_read, id); // Toggling based on InputSwitch value
            }}
            className="input-switch"
            tooltip={is_read ? 'Mark as Unread' : 'Mark as Read'}
            tooltipOptions={{ position: 'top' }}
          /> */}
          <div className="status-mark d-inline-table">
            <a
              href="/"
              onClick={(e) => handleMessageStatus(e, rowData, is_read, id)}
              className={'F-size12 text-decoration-none'}
              title={rowData.is_read ? 'Mark as Unread' : 'Mark as Read'}
            >
              {rowData.is_read ? 'Mark as Unread' : <span>Mark as Read</span>}
            </a>
          </div>
        </div>
      </>
    );
  };

  const attachmentsBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title text-break">Attachments</span>
        <div className="upload-document d-inline-table">
          {rowData?.attachment?.length || rowData?.event_attachment?.length || rowData?.task_attachment?.length ? (
            <>
              {rowData.attachment && rowData.attachment.length ? (
                <p
                  className={rowData.is_read ? 'text-muted pointer text-break' : 'pointer text-break mb-2 fw-bold'}
                  onClick={(e) => {
                    onClickAttachments(e, rowData.attachment[0]);
                  }}
                >
                  <i className="fas fa-file-pdf redirection-link"></i>
                  <span className="ms-1 redirection-link" title={rowData.attachment[0]?.file_name}>
                    {/* {rowData.attachment[0]?.file_name} */}
                    {truncateText(rowData.attachment[0]?.file_name, 10)}
                  </span>
                </p>
              ) : rowData.event_attachment && rowData.event_attachment.length ? (
                <p
                  className={rowData.is_read ? 'text-muted text mb-1 text-break' : 'fw-bold small mb-1 text-break'}
                  onClick={(e) => {
                    handleNavigateToMessageThread(e, rowData);
                  }}
                >
                  <i className="far fa-calendar" /> {rowData?.event_attachment?.[0]?.event_name || '-'}
                </p>
              ) : rowData.task_attachment && rowData.task_attachment.length ? (
                <p
                  className={rowData.is_read ? 'text-muted small mb-1 text-break' : 'fw-bold small mb-1 text-break'}
                  onClick={(e) => {
                    handleNavigateToMessageThread(e, rowData);
                  }}
                >
                  <i className="fas fa-tasks" /> {rowData?.task_attachment?.[0]?.task_name || '-'}
                </p>
              ) : null}
            </>
          ) : (
            '-'
          )}
        </div>
      </>
    );
  };

  const repliesTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title text-break">Most Recent Replies</span>
        <div
          className="d-inline-table text-break"
          onClick={(e) => {
            handleNavigateToMessageThread(e, rowData);
          }}
        >
          <div>
            <p
              className={
                rowData.is_read
                  ? 'mb-1 F-size14 black-600 message-date-ellipsis-text'
                  : ' mb-1 text-bold F-size14 black-600 message-date-ellipsis-text'
              }
              title={handleDateTimeOffset(
                userContext?.userDetails?.timezone,
                rowData?.created_at,
                constants.month_date_year_time_12_format
              )}
            >
              {handleDateTimeOffset(userContext?.userDetails?.timezone, rowData?.created_at, constants.month_date_year_time_12_format)}
            </p>
            {rowData.last_reply ? (
              <p
                className={
                  rowData.is_read
                    ? 'text-hint black-500 mb-1 r-me-0 message-date-ellipsis-text'
                    : 'text-hint black-500 r-me-0 message-date-ellipsis-text'
                }
                title={`Last Reply: ${handleDateTimeOffset(
                  userContext?.userDetails?.timezone,
                  rowData?.last_reply,
                  constants.month_date_year_time_12_format
                )}`}
              >
                Last Reply :{' '}
                {handleDateTimeOffset(userContext?.userDetails?.timezone, rowData?.last_reply, constants.month_date_year_time_12_format)}
              </p>
            ) : null}
          </div>
        </div>
      </>
    );
  };

  const subjectBodyTemplate = (rowData) => {
    // Function to remove HTML tags from the message
    const stripHtmlTags = (htmlString) => {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = htmlString;

      // Check if there are any <li> tags in the HTML
      const listItems = tempDiv.querySelectorAll('li');

      // If <li> tags are found, return them as a formatted list
      if (listItems.length > 0) {
        return Array.from(listItems)
          .map((li, index) => `${index + 1}. ${li.textContent || li.innerText}`) // Numbered list
          .join('\n'); // Use newline characters to separate items
      }

      return tempDiv.textContent || tempDiv.innerText || '';
    };
    return (
      <>
        <span className="p-column-title text-break">Subject</span>
        <div className="d-inline-table">
          <div
            className={
              rowData.is_read
                ? 'pointer text-break text-primary-dark message-ellipsis-text'
                : 'pointer text-bold text-capitalize text-primary-dark message-ellipsis-text'
            }
            onClick={(e) => {
              handleNavigateToMessageThread(e, rowData);
            }}
            title={rowData?.subject}
          >
            {rowData?.subject}
          </div>
          {rowData.reply_count ? (
            <span
              className={rowData.is_read ? 'text-hint black-500 r-me-0 ' : 'text-hint black-500 r-me-0'}
              style={{ fontSize: 'small' }}
              title={parseInt(rowData.reply_count) > 1 ? `${parseInt(rowData.reply_count)} Replies` : `1 Reply`}
            >
              {parseInt(rowData.reply_count) > 1 ? `${parseInt(rowData.reply_count)} Replies` : `1 Reply`}
            </span>
          ) : null}
          {/* Render the plain text version of the message, truncated to 100 characters if needed */}
          {rowData?.message && (
            <div className="text-muted mt-2">
              <span className="truncate-2 text-break" title={stripHtmlTags(rowData.message)}>
                {stripHtmlTags(rowData.message).length > 30
                  ? `${stripHtmlTags(rowData.message).substring(0, 30)}...`
                  : stripHtmlTags(rowData.message)}
              </span>
            </div>
          )}
        </div>
      </>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title text-break">Status</span>
        <div
          className="d-inline-table"
          onClick={(e) => {
            history.push({ pathname: `messages/${rowData?.message_thread_id}`, state: { msg: rowData } });
            if (!rowData.is_read) {
              handleMessageStatus(e, rowData, rowData.is_read, id);
            }
          }}
        >
          {rowData.is_new ? <Tag className="tag-case s-tag bg-light" value="New"></Tag> : null}
        </div>
      </>
    );
  };

  const sentimentBody = (rowData) => {
    const params = {
      type: rowData?.aggregate_sentiment,
      positive: rowData?.positive_sentiment_count,
      neutral: rowData?.neutral_sentiment_count,
      negative: rowData?.negative_sentiment_count,
      mixed: rowData?.mixed_sentiment_count,
    };
    return (
      <>
        <span className="p-column-title text-break">Sentiment</span>
        <div className="d-inline-table">
          <SentimentDot {...params} />
        </div>
      </>
    );
  };

  const onClickAttachments = async (e, fileInfo) => {
    e.preventDefault();
    const link = await getSignedUrl(fileInfo);
    downloadFile(link, fileInfo.display_name);
  };

  const paginatorTemplate = {
    layout: 'RowsPerPageDropdown PrevPageLink NextPageLink',
    RowsPerPageDropdown: (options) => {
      return (
        <React.Fragment>
          <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
            Item Per Page
          </span>
          <Dropdown value={options.value} options={paginationDropdownOptions} onChange={onPageChange} />
        </React.Fragment>
      );
    },
    NextPageLink: ({ iconClassName }) => {
      return (
        <Button
          onClick={onNextPageClick}
          disabled={next_Token === null && isForward}
          className={'p-paginator-next p-paginator-element p-link'}
          icon={iconClassName}
        />
      );
    },
    PrevPageLink: ({ iconClassName }) => {
      return (
        <Button
          onClick={onPrevPageClick}
          disabled={transition_Token === null || (transition_Token && next_Token === null && !isForward)}
          className={'p-paginator-next p-paginator-element p-link'}
          icon={iconClassName}
        />
      );
    },
  };

  const onNextPageClick = () => {
    let obj = {
      case_id: filterCaseId,
      author_id: filterAuthorId,
      message_date: false,
      is_read: filterMsgStatus,
      keyword: false,
      participant: filterParticipant,
      sentiment_filter: filterSentiment,
      pageLimit: pageLimit,
      pagination_token: !isForward ? transition_Token : next_Token,
      pagination_direction: 'forward',
    };
    fetchCaseMessage(obj);
    setIsForward(true);
  };

  const onPrevPageClick = () => {
    let obj = {
      case_id: filterCaseId,
      author_id: filterAuthorId,
      message_date: false,
      is_read: filterMsgStatus,
      keyword: false,
      participant: filterParticipant,
      sentiment_filter: filterSentiment,
      pageLimit: pageLimit,
      pagination_token: isForward ? transition_Token : next_Token,
      pagination_direction: 'reverse',
    };
    fetchCaseMessage(obj);
    setIsForward(false);
  };
  const onPageChange = (event) => {
    setPageLimit(event.value);
    let path = getFilePathForPagination();
    handlePageNumberInPagination(userContext, path?.module, path?.subModule, path?.listName, event.value);
    let obj = {
      case_id: filterCaseId?.value ? filterCaseId?.value : filterCaseId,
      author_id: filterAuthorId,
      message_date: false,
      is_read: filterMsgStatus,
      keyword: false,
      participant: filterParticipant,
      sentiment_filter: filterSentiment,
      pageLimit: event.value,
      pagination_token: '',
      pagination_direction: 'forward',
    };
    fetchCaseMessage(obj);
  };

  return (
    <>
      <div className="datatable-responsive">
        <DataTable
          value={caseMessages.message}
          breakpoint="960px"
          className="cursor-pointer p-datatable-responsive"
          paginator
          paginatorTemplate={paginatorTemplate}
          rows={pageLimit}
        >
          <Column
            field="subject"
            header="Subject"
            className="message-subject"
            body={subjectBodyTemplate}
            sortable
            // bodyClassName="message-ellipsis-text"
          />
          <Column
            field="author_name"
            header="Author"
            className="author-width"
            body={authorBodyTemplate}
            sortable
            style={{ cursor: 'text' }}
          />
          {isGlobalMessagesScreen && (
            <Column field="case_name" header="Case Name" body={caseNameBodyTemplate} sortable bodyClassName="ellipsis-text" />
          )}
          <Column field="is_new" header="Status" body={statusBodyTemplate} sortable />
          <Column field="sk" header="Most Recent Replies" body={repliesTemplate} sortable />
          <Column field="attachment" header="Attachments" body={attachmentsBodyTemplate} sortable style={{ width: '150px' }} />
          {!isFromClientPortal && (
            <Column
              field="aggregate_sentiment"
              header="Sentiment"
              body={sentimentBody}
              sortable
              className="md-align-center"
              headerStyle={{ textAlign: 'center' }}
            />
          )}
          <Column field="is_read" header="" body={stateBodyTemplate} bodyClassName="ellipsis-text" />
        </DataTable>
      </div>
    </>
  );
}

export default MessageListTable;
