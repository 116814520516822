import { notificationTypes } from 'constants/index';
import React, { createContext, useState, useContext } from 'react';

import { connectToSocket } from 'services/notification/notificationService';
import Cookies from 'universal-cookie';
const cookie = new Cookies();
const WebSocketConnectionContext = createContext(null);

export const useWebSocketContext = () => useContext(WebSocketConnectionContext);

const WebSocketConnectionProvider = (props) => {
  const [notificationPopup, setNotificationPopup] = useState(false);
  const [notificationData, setNotificationData] = useState();
  const [response, setResponse] = useState();
  const [secureMessageData, setSecureMessageData] = useState();
  const [sideBarMessages, setSideBarMessages] = useState();
  const [secureMessageError, setSecureMessageError] = useState();
  const [assignedTaskData, setAssignedTaskData] = useState();
  const [textMessageData, setTextMessageData] = useState();
  const [timeEntryData, setTimeEntryData] = useState();
  const [clientLogout, setClientLogout] = useState();
  const [automationErrorLogData, setAutomationErrorLogData] = useState();
  const [aiFileSummary, setAiFileSummary] = useState();
  const [aiCourtDate, setAiCourtDate] = useState();
  let interval = '';
  let [socket, setSocket] = useState();

  const connectToWebSocket = async (tenant_id, user, access_level) => {
    if (!socket) {
      let _socket = connectToSocket(tenant_id, user, access_level);
      setSocket(_socket);
      _socket.onopen = (e) => {
        // console.log("connection open");
      };
      _socket.onmessage = (e) => {
        let data = JSON.parse(e?.data);
        if (data) {
          if (notificationTypes?.includes(data?.notification_type)) {
            setNotificationData(data);
          }

          if (data?.notification_type === 'server_status') {
            if (data?.body?.execution_status === 'success') {
              setResponse({ ...data });
            } else {
              setSecureMessageError({ ...data });
            }
          } else if (data?.notification_type === 'sidebar_message') {
            setSideBarMessages({ ...data });
          } else if (data?.notification_type === 'secure_message') {
            setSecureMessageData({ ...data });
          } else if (data?.notification_type === 'assignment') {
            setAssignedTaskData({ ...data });
          } else if (data?.notification_type === 'time_entry_response') {
            setTimeEntryData({ ...data });
          } else if (data?.notification_type === 'firm_Admin_change') {
            setAssignedTaskData({ ...data });
          } else if (data?.notification_type === 'firm_deactivated') {
            setAssignedTaskData({ ...data });
          } else if (data?.notification_type === 'inbound_text_message') {
            setTextMessageData({ ...data });
          } else if (data?.notification_type === 'outbound_status_callback') {
            setTextMessageData({ ...data });
          } else if (data?.notification_type === 'automation_error_logs') {
            setAutomationErrorLogData({ ...data });
          } else if (data && ['user_log_out', 'notification_type', 'archive_user', 'user_data_changed'].includes(data?.notification_type)) {
            setClientLogout({ ...data });
          } else if (data?.notification_type === 'ai_summary_message') {
            setAiFileSummary({ ...data });
          } else if (data?.notification_type === 'ai_court_date_message') {
            setAiCourtDate({ ...data });
          }
        }
      };
      _socket.onerror = (e) => {
        console.log('on connection error', e);
      };
      _socket.onclose = (e) => {
        setSocket();
        _socket = null;
        let tenantId = cookie.get('tenantId') || null;
        if (!!tenantId) {
          // in case of logout the local storage is cleared and the websocket won't be reconnected
          connectToWebSocket(tenant_id, user);
        }
      };
    }
  };

  const sendMessage = async (data) => {
    try {
      return await socket.send(JSON.stringify(data), (res) => {
        console.log('websocket_reponse', res);
      });
    } catch (err) {
      console.log('websocket_error', err);
    }
  };

  const closeConnection = () => {
    try {
      if (socket) {
        socket.close();
      }
      clearInterval(interval);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <WebSocketConnectionContext.Provider
      value={{
        connectToWebSocket: connectToWebSocket,
        notificationPopup: notificationPopup,
        notificationData: notificationData,
        setNotificationPopup: setNotificationPopup,
        sendMessage: sendMessage,
        closeConnection: closeConnection,
        response: response,
        setResponse: setResponse,
        setSecureMessageData: setSecureMessageData,
        secureMessageData: secureMessageData,
        secureMessageError: secureMessageError,
        setSecureMessageError: setSecureMessageError,
        setNotificationData: setNotificationData,
        assignedTask: assignedTaskData,
        setAssignedTaskData: setAssignedTaskData,
        textMessageData: textMessageData,
        setTextMessageData: setTextMessageData,
        timeEntryData: timeEntryData,
        clientLogout: clientLogout,
        automationErrorLogData: automationErrorLogData,
        setAutomationErrorLogData: setAutomationErrorLogData,
        sideBarMessages,
        setSideBarMessages,
        aiFileSummary: aiFileSummary,
        aiCourtDate: aiCourtDate,
      }}
    >
      {props.children}
    </WebSocketConnectionContext.Provider>
  );
};

const WebSocketConnectionConsumer = WebSocketConnectionContext.Consumer;

export { WebSocketConnectionContext, WebSocketConnectionProvider, WebSocketConnectionConsumer };
